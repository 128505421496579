#root{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    overflow: hidden;
  }


  ::-webkit-scrollbar {
    width: 7px;
  }
  
  ::-webkit-scrollbar-track {
    background: var(--lightgrey);
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--primary-selected);
  }