.modal-message-container {
    .modal-message {
        font-size: 15px;
    }
}

.dialog-actions-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.overflow-disabled {
    overflow-y: hidden !important;
    padding: 0;
}