.company-document-handler-container {
  display: flex;
  flex-direction: column;

  .section-title {
    font-weight: 600;
    font-size: 1.1rem;
    text-align: center;
  }

  .company-document-handler-download-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .documents-container {
      display: flex;
      cursor: pointer;
      align-items: baseline;
    }

    .company-document {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 100px;

      .document-name {
        font-weight: 600;
        color: var(--primary-hover);
        text-align: center;
      }
    }
  }
}
