.dashboard-card {
  padding: 1rem;
  min-width: 20rem;
  border-bottom-right-radius: 40px 40px !important;

  &.clickable {
    cursor: pointer;
  }

  .dashboard-header-card {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dashboard-card-title-container {
      display: flex;
      flex-direction: column;

      .card-title {
        color: var(--primary-hover);
        font-weight: 600;
        font-size: 1.1rem;
      }
    }

    .card-icon {
      font-size: 2rem;
      color: var(--primary-hover);
    }
    
    .card-subtitle {
      color: var(--black);
      font-weight: 600;
      font-size: 1rem;
    }
  }

  .dashboard-card-content {
  }

}
