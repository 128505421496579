:root {
    --primary: rgba(6, 173, 245, 1);
    --primary-hover: rgba(42, 128, 181, 1);
    --primary-disabled: 'red';
    --primary-selected: #83daff;
    --primary-transparent: rgba(6, 173, 245, 0.3);
    --secondary: #F66374;
    --secondary-hover: #BC4654;
    --secondary-disabled: 'red';
    --secondary-selected: 'red';
    --secondary-transparent: #2A80B5;
    --disabled: #A6A6A6;
    --black: #404B4C;
    --white: #fefefe;
    --transparent: 'transparent';
    --transparent-dark: rgba(100, 100, 100, 0.3);
    --lightgrey: #E0E0E0;
    --darkgrey: 	rgb(105,105,105);
    --silver: rgb(192,192,192);
    --green: #1E7310;
    --orange: #E89125;
    --red: #BC4654;
  }

  .App {
    width: 100vw;
    height: 100vh;
  }

  * {
    font-family: Nunito, Arial;
  }

  .nav-link {
    text-decoration: none;
    color: inherit;
    width:100%;
  }

.fixed {
     position:fixed;
     bottom:0;
     width:100%;
   }

   .clickable-icon {
     cursor: pointer;
   }

.alert-title-modal {
  color: var(--red);
}