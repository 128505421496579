.pdf-viewer-container {
  border: "1px solid rgba(0, 0, 0, 0.3)";
  height: 500px;
}

.pdf-header {
  display: flex;
  align-items: center;
  justify-content: center;

  .fileName {
    font-weight: 600;
    color: var(--primary-hover);
  }
}

.rpv-core-button {
  display: flex;

  .rpv-core-icon {
    stroke: var(--primary-hover);
    stroke-width: 2;
    font-size: large;
  }
}
