.validation-view-container {
    display: flex;
    flex-direction: column;
    min-height: 20rem;

    .validation-table-container {
      min-height: 20rem;
      display: flex;
      flex-direction: column;
    }
}

.validation-actions-container {
    display: flex;

    .action-button {
      border-radius: 20px;
      border: 2px solid;
      font-weight: 700;

      &.reject{
        border: 2px solid var(--red);
        color: var(--red);

        &.disabled{
          border: 2px solid var(--disabled);
          color: var(--disabled);
        }
      }
    }
  }