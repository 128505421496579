.tooltip {
  height: 15px;
  width: 15px;
  border-radius: 50px;

  &.default {
    background-color: var(--darkgrey);
  }

  &.green {
    background-color: var(--green);
  }

  &.orange {
    background-color: var(--orange);
  }

  &.red {
    background-color: var(--red);
  }

  &.white {
    background-color: var(--white);
    border: 1px solid var(--darkgrey);
  }
}
