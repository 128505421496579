.document-preview-container {
  display: flex;
  flex-direction: column;

  .document-preview-row {
    display: flex;

    .label {
      font-size: 1rem;
    }
    .document-name,
    .document-type {
      font-weight: 600;
      font-size: 1rem;
    }
  }
  .download-container {
    display: flex;
    align-items: center;
    .document-download-link {
      color: var(--primary-hover);
      font-weight: 600;
      text-decoration: none;
      font-size: 1rem;
    }
  }
}
