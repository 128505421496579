:root {
  --xs: 0.25rem;
  --s: 0.5rem;
  --m: 0.75rem;
  --l: 1rem;
  --xl:1.5rem;
  --xxl: 3rem;
}

.spacer {
  flex-shrink: 0;
  &.dynamic {
    flex-grow: 1;
  }
}

.xs {
  &.horizontal { width: var(--xs) }
  &.vertical { height: var(--xs) }
}

.s {
  &.horizontal { width: var(--s) }
  &.vertical { height: var(--s) }
}

.m {
  &.horizontal { width: var(--m)}
  &.vertical { height: var(--m) }
}

.l {
  &.horizontal { width: var(--l)}
  &.vertical { height: var(--l) }
}

.xl {
  &.horizontal { width: var(--xl) }
  &.vertical { height: var(--xl) }
}

.xxl {
  &.horizontal { width: var(--xxl) }
  &.vertical { height: var(--xxl) }
}