.tag {
  padding: 0.1rem 0.5rem;
  border-radius: 5rem;
  font-size: 14px;
  min-width: 2.5rem;
  text-align: center;
  font-weight: 700;
  color: var(--white);

  &.green {
    background-color: var(--green);
    border: 2px solid var(--green);
  }
  &.orange {
    background-color: var(--orange);
    border: 2px solid var(--orange);
  }
  &.red {
    background-color: var(--red);
    border: 2px solid var(--red);
  }
}
