.roles-tag-container {
  display: flex;

  .tag-role {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50px;
    border: 2px solid var(--primary-hover);
    display: flex;
    justify-content: center;
    align-items: center;

    .tag-label {
      color: var(--primary-hover);
      font-weight: 600;
      font-size: 0.8rem;
    }
  }
}
