.dashboard-view-container {
  display: flex;
  flex-direction: column;
  min-height: 40rem;
  padding: 1.5rem;
  width: 100%;

  .dashboard-view-content {
      display: flex;
      flex-direction: column;
      
  .dashboard-row {
    display: flex;
    flex-wrap: wrap;
}
  }

}
