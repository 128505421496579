  .title-row {
    padding: 0.75em 0em;

    .title {
      font-weight: 700;
      color: var(--primary-hover);
      font-size: 27px;
    }
  }
  

  