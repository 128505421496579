.history-view-container {
    display: flex;
    flex-direction: column;
    height:20rem;
    width:20rem;
    overflow: auto !important;

    .history-table-container {
        display: flex;
        flex-direction: column;
    }
}
