.companies-list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 105px;
    overflow-y: auto;

    .company-item {
        font-size: 16px;
        color: var(--black);
        font-weight: 600;
    }
}